import React, { useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import styles from "./LoyaltyProgramCategoryTemplate.module.scss";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  PatchLoyaltyProgramServiceCategoryData,
  PostLoyaltyProgramServiceCategoryData,
  useGetLoyaltyProgramsServiceCategoryQuery,
  usePatchLoyaltyProgramServiceCategoryMutation,
  usePostLoyaltyProgramServiceCategoryMutation,
} from "../../../redux/services/loyaltyProgramApi";
import { LoyaltyProgramServiceCategoryTable } from "../../organisms/Tables/LoyaltyProgramServiceCategoryTable/LoyaltyProgramServiceCategoryTable";
import { useTranslation } from "react-i18next";

const INITIAL_SEARCH_PARAMS = {
  PAGE: 1,
  LIMIT: 10,
  OFFSET: 0,
  TOTAL_RESULTS: 0,
  ALL_CATEGORY: "1",
  HIDDEN: "0",
};

export interface EditObj extends PatchLoyaltyProgramServiceCategoryData {
  id: number;
}

export const LoyaltyProgramCategoryTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState<number>(
    Number(searchParams.get("page")) || INITIAL_SEARCH_PARAMS.PAGE,
  );
  const [editObj, setEditObj] = useState<EditObj | null>(null);

  const {
    data: loyaltyProgramsServiceCategoryData,
    isLoading: loyaltyProgramsServiceCategoryIsLoading,
  } = useGetLoyaltyProgramsServiceCategoryQuery({
    offset: String(
      currPage
        ? (currPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT
        : INITIAL_SEARCH_PARAMS.OFFSET,
    ),
    limit: String(INITIAL_SEARCH_PARAMS.LIMIT),
    allCategory: INITIAL_SEARCH_PARAMS.ALL_CATEGORY,
    hidden: INITIAL_SEARCH_PARAMS.HIDDEN,
  });

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<
    | PostLoyaltyProgramServiceCategoryData
    | PatchLoyaltyProgramServiceCategoryData
  >({
    defaultValues: {
      name: "",
      priority: 1,
      status: 1,
      show: 1,
    },
  });

  useEffect(() => {
    if (editObj === null) {
      setValue("name", "");
      setValue("priority", 1);
      setValue("status", 1);
      setValue("show", 1);
      setValue("slug", null);
    } else {
      setValue("name", editObj.name);
      setValue("priority", editObj.priority);
      setValue("status", editObj.status);
      setValue("show", editObj.show);
      setValue("slug", editObj.slug);
    }
    // eslint-disable-next-line
  }, [editObj]);

  const [postLoyaltyProgramServiceCategory] =
    usePostLoyaltyProgramServiceCategoryMutation();

  const [patchLoyaltyProgramServiceCategory] =
    usePatchLoyaltyProgramServiceCategoryMutation();

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  const submitHandler: SubmitHandler<
    | PostLoyaltyProgramServiceCategoryData
    | PatchLoyaltyProgramServiceCategoryData
  > = async (
    data:
      | PostLoyaltyProgramServiceCategoryData
      | PatchLoyaltyProgramServiceCategoryData,
  ): Promise<void> => {
    if (editObj === null) {
      const hasBeenCreate = await postLoyaltyProgramServiceCategory({
        data: data as PostLoyaltyProgramServiceCategoryData,
      });

      if ("data" in hasBeenCreate) {
        toast.success(t("toast_loyaltyProgramCategoryTemplate_add_success"));
      } else if ("error" in hasBeenCreate) {
        const error = hasBeenCreate.error as TranslationSubmitError;
        if (error.data?.statusCode === 500)
          toast.error(t("toast_loyaltyProgramCategoryTemplate_add_error_500"));

        if (error.data?.statusCode === 400)
          toast.error(
            `${t("toast_loyaltyProgramCategoryTemplate_add_error_400")} ${error
              .data?.message[0]}`,
          );
      }
    } else {
      const hasBeenUpdated = await patchLoyaltyProgramServiceCategory({
        id: editObj.id,
        data: data as PatchLoyaltyProgramServiceCategoryData,
      });

      if ("data" in hasBeenUpdated) {
        toast.success(t("toast_loyaltyProgramCategoryTemplate_update_success"));
      } else if ("error" in hasBeenUpdated) {
        const error = hasBeenUpdated.error as TranslationSubmitError;
        if (error.data?.statusCode === 500)
          toast.error(
            t("toast_loyaltyProgramCategoryTemplate_update_error_500"),
          );

        if (error.data?.statusCode === 404)
          toast.error(
            t("toast_loyaltyProgramCategoryTemplate_update_error_404"),
          );

        if (error.data?.statusCode === 400)
          toast.error(
            `${t(
              "toast_loyaltyProgramCategoryTemplate_update_error_400",
            )} ${error.data?.message[0]}`,
          );
      }
    }
  };

  const handleEdit = async (data: EditObj, now: boolean) => {
    if (now) {
      await patchLoyaltyProgramServiceCategory({ id: data.id, data: data });
    } else {
      setEditObj(data);
    }
  };

  return (
    <div className="mt-2 px-4 w-100">
      <h1 className="pe-3 fs-2 my-3 text-dark-blue">
        Kategorie benefitów w programie lojalnościowym
      </h1>
      <div className="row w-100">
        <form
          className={`col ${styles.categoriesForm}`}
          onSubmit={handleSubmit(submitHandler)}
        >
          <p className="fw-bold text-dark-blue">
            {editObj === null ? "Utwórz kategorie" : "Zapisz kategorie"}
          </p>
          <ComponentWithDescription
            label={"Nazwa kategorii*"}
            labelFs={`fs-16 ${errors.name ? "text-danger" : "text-dark-blue"}`}
            className="my-2 pb-3"
          >
            <Form.Control
              type="text"
              id="case"
              aria-describedby="case"
              className={`w-100 text-dark-blue ${styles.input} `}
              {...register("name", {
                required: { value: true, message: "Pole wymagane" },
              })}
              isInvalid={Boolean(errors.name)}
            />
            {errors.name && (
              <Form.Control.Feedback type={"invalid"}>
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </ComponentWithDescription>
          <ComponentWithDescription
            label={"Slug kategorii"}
            labelFs={`fs-16 ${errors.name ? "text-danger" : "text-dark-blue"}`}
            className="my-2 pb-3"
          >
            <Form.Control
              disabled={!!watch("show")}
              type="text"
              aria-describedby="case"
              className={`w-100 text-dark-blue ${styles.input} `}
              {...register("slug", {
                required: { value: !watch("show"), message: "Pole wymagane" },
              })}
              isInvalid={Boolean(errors?.slug)}
            />
            {errors?.slug && (
              <Form.Control.Feedback type={"invalid"}>
                {errors?.slug?.message}
              </Form.Control.Feedback>
            )}
          </ComponentWithDescription>
          <Controller
            name={"status"}
            control={control}
            defaultValue={1}
            render={({ field: { value, onChange } }) => (
              <Form.Check
                className={"mb-4 text-dark-blue"}
                type="switch"
                checked={value === 1}
                label="Status"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(e.target.checked ? 1 : 0)
                }
              />
            )}
          />
          <Controller
            name={"priority"}
            control={control}
            defaultValue={1}
            render={({ field: { value, onChange } }) => (
              <Form.Check
                className={"mb-4 text-dark-blue"}
                type="switch"
                checked={value === 10}
                label="Priorytet premium"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(e.target.checked ? 10 : 1)
                }
              />
            )}
          />
          <Controller
            name={"show"}
            control={control}
            defaultValue={1}
            render={({ field: { value, onChange } }) => (
              <Form.Check
                className={"mb-4 text-dark-blue"}
                type="switch"
                checked={value === 0}
                label="Profilowanie, ukryta"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.checked ? 0 : 1);
                  if (!e.target.checked) {
                    setValue("slug", null);
                  }
                }}
              />
            )}
          />
          <div className="d-flex gap-3 col-auto mb-4">
            <Button type="submit" value="Submit">
              {editObj === null ? "Utwórz kategorię" : "Zapisz zamiany"}
            </Button>
            {editObj !== null && (
              <Button
                className="btn-outline-blue"
                onClick={() => setEditObj(null)}
              >
                Anuluj
              </Button>
            )}
          </div>
        </form>
        {!loyaltyProgramsServiceCategoryIsLoading && (
          <div className="col bd-highlight">
            <LoyaltyProgramServiceCategoryTable
              data={loyaltyProgramsServiceCategoryData?.data}
              handleEdit={handleEdit}
            />
            <CustomPagination
              totalCount={
                loyaltyProgramsServiceCategoryData.meta?.totalResults ||
                INITIAL_SEARCH_PARAMS.TOTAL_RESULTS
              }
              pageSize={INITIAL_SEARCH_PARAMS.LIMIT}
              currentPage={currPage}
              onPageChange={(e: number) => handlePageChange(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
