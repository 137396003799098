import { Row, Stack } from "react-bootstrap";
import styles from "./ChatsHistory.module.scss";
import { Chat } from "../Chat/Chat";
import { useSelector, useDispatch } from "react-redux";
import { setUnreadMessages } from "redux/Slice/messages-slice";
import { RootState } from "../../../redux/store/index";
import sortByNewestMessage from "Helpers/sortMessages";
import ChatArchive from "components/atoms/ChatArchive/ChatArchive";

type ChatsHistoryProps = {
  chats: ChatData[];
  isOnMessenger?: boolean;
  className?: string;
};

export const ChatsHistory = ({
  chats,
  isOnMessenger,
  className,
}: ChatsHistoryProps) => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const currentUserId = !isOnMessenger ? authUser?.id : null;
  const dispatch = useDispatch();

  const CLOSED_CONVERSATION_STATUS = 4;

  const isLastMessageUnreadByCurrentUser = (
    chat: ChatData,
    currentUserId: number | null,
    isOnMessenger: boolean | undefined,
  ) => {
    if (isOnMessenger) {
      return chat.topicMessage.some(
        (message) =>
          message.isUnreadMessage === 1 &&
          message.senderUser?.id !== currentUserId &&
          message.senderUser !== null,
      );
    } else {
      const lastMessageIndex = chat.topicMessage.length - 1;
      const lastMessage = chat.topicMessage[lastMessageIndex];
      return (
        lastMessage?.isUnreadMessage === 1 &&
        lastMessage?.senderUser?.id !== currentUserId
      );
    }
  };

  const unreadChats = chats?.filter(
    (chat) =>
      (isOnMessenger
        ? chat?.receiverUser === null
        : chat?.receiverUser != null) &&
      chat?.status !==
        (isOnMessenger ? CLOSED_CONVERSATION_STATUS : undefined) &&
      isLastMessageUnreadByCurrentUser(chat, currentUserId, isOnMessenger),
  );

  const readChats = chats?.filter(
    (chat) =>
      (isOnMessenger
        ? chat?.receiverUser === null
        : chat?.receiverUser != null) &&
      chat?.status !==
        (isOnMessenger ? CLOSED_CONVERSATION_STATUS : undefined) &&
      !isLastMessageUnreadByCurrentUser(chat, currentUserId, isOnMessenger),
  );

  const supportChats = chats?.filter(
    (chat) =>
      chat?.receiverUser == null && chat?.status !== CLOSED_CONVERSATION_STATUS,
  );

  const archiveChats = chats?.filter(
    (chat) => chat?.status === CLOSED_CONVERSATION_STATUS,
  );

  unreadChats?.sort(sortByNewestMessage);
  readChats?.sort(sortByNewestMessage);
  supportChats?.sort(sortByNewestMessage);
  archiveChats?.sort(sortByNewestMessage);

  const hasUnreadFromOtherUsers = unreadChats.length > 0;
  dispatch(setUnreadMessages(hasUnreadFromOtherUsers));

  return (
    <>
      {/*zamiana Messengera na inny system*/}
      {/*https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76*/}
      {/*{supportChats.length > 0 && !isOnMessenger && (*/}
      {/*  <>*/}
      {/*    <Row*/}
      {/*      className={`col-12 m-0 d-flex justify-content-center align-items-center mb-4 ${styles.fitContent}`}*/}
      {/*    >*/}
      {/*      <span*/}
      {/*        className={`${styles.readIndicator} text-center w-100 col-12`}*/}
      {/*      >*/}
      {/*        <span className={`${styles.readIndicatorText}`}>Wsparcie</span>*/}
      {/*        <span className={`${styles.readIndicatorLine}`} />*/}
      {/*      </span>*/}
      {/*    </Row>*/}
      {/*    <Stack*/}
      {/*      gap={2}*/}
      {/*      className={`d-flex px-0 mx-0 mb-4 ${styles.fitContent} ${className}`}*/}
      {/*    >*/}
      {/*      {supportChats.map((chatsData) => (*/}
      {/*        <Chat*/}
      {/*          data={chatsData}*/}
      {/*          key={chatsData?.id}*/}
      {/*          isOnMessenger={isOnMessenger}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </Stack>*/}
      {/*  </>*/}
      {/*)}*/}
      {unreadChats.length > 0 && (
        <>
          <Row
            className={`col-12 m-0 d-flex justify-content-center align-items-center mb-4 mt-4 ${styles.fitContent}`}
          >
            <span
              className={`${styles.readIndicator} text-center w-100 col-12`}
            >
              <span className={`${styles.readIndicatorText}`}>
                Nieprzeczytane
              </span>
              <span className={`${styles.readIndicatorLine}`} />
            </span>
          </Row>
          <Stack
            gap={2}
            className={`d-flex px-0 mx-0 mb-4 ${styles.fitContent}`}
          >
            {unreadChats.map((chatsData) => {
              return (
                <Chat
                  data={chatsData}
                  key={chatsData?.id}
                  isOnMessenger={isOnMessenger}
                />
              );
            })}
          </Stack>
        </>
      )}
      {readChats.length > 0 && (
        <>
          <Row
            className={`col-12 m-0 d-flex justify-content-center align-items-center mb-4 mt-4 ${styles.fitContent}`}
          >
            <span
              className={`${styles.readIndicator} text-center w-100 col-12`}
            >
              <span className={`${styles.readIndicatorText}`}>Przeczytane</span>
              <span className={`${styles.readIndicatorLine}`} />
            </span>
          </Row>
          <Stack gap={2} className={`d-flex px-0 mx-0 ${styles.fitContent}`}>
            {readChats.map((chatsData) => {
              return (
                <Chat
                  data={chatsData}
                  key={chatsData?.id}
                  isOnMessenger={isOnMessenger}
                />
              );
            })}
          </Stack>
        </>
      )}
      <ChatArchive messages={archiveChats} isOnMessenger={isOnMessenger} />
    </>
  );
};
