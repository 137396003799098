import { cancers } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface GetCancersSearchParams {
  limit?: string;
  offset?: string;
  sortBy?: string;
  nameLike?: string;
  code?: string;
  categoryIds?: string;
  status?: string;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Cancer"],
});

export const cancersApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCancers: builder.query({
      query: ({
        limit,
        offset,
        sortBy,
        nameLike,
        code,
        categoryIds,
        status,
      }: GetCancersSearchParams) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(nameLike && { nameLike }),
          ...(code && { code }),
          ...(categoryIds && { categoryIds }),
          ...(status && { status }),
        });

        return {
          url: `${cancers}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Cancer"],
    }),

    getCancer: builder.query({
      query: ({ id }: { id?: string }) => {
        return {
          url: `${cancers}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Cancer"],
    }),

    patchCancerUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url: `${cancers}/${id}/update`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Cancer"],
    }),

    postCancer: builder.mutation({
      query: ({ data }) => ({
        url: `${cancers}/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Cancer"],
    }),
  }),
});

export const {
  useGetCancersQuery,
  useLazyGetCancersQuery,
  usePatchCancerUpdateMutation,
  useGetCancerQuery,
  usePostCancerMutation,
} = cancersApi;
