import { feed } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface PostFeedDataProps {
  data: {
    url: string;
  };
}

interface GetCommentsDataProps {
  feedId?: string;
  limit?: string;
  offset?: string;
  status?: string;
  sortOrder?: string;
  nameLike?: string;
}

interface PostPostsAbuseData {
  reviewId: number;
  content: string;
}

export interface PostPostsAbusePayload {
  data: PostPostsAbuseData;
}

interface PatchPostsAbuseData {
  content?: string;
  answer?: string;
  decision?: number;
}
export interface PatchPostsAbusePayload {
  id: number;
  data: PatchPostsAbuseData;
}
export interface PatchFiltersPayload {
  id: number;
  data: PatchFiltersData;
}

interface PatchFiltersData {
  name: string;
}

interface PostFeedTagsProps {
  data: {
    tag: string;
    status: number;
  };
}
interface PostFeedTopicsProps {
  data: {
    topic: string;
    status: number;
  };
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Feed", "PatchFeedAbuse"],
});

export const FeedApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: ({
        offset,
        limit,
        feedId,
        tagId,
        topicId,
        userId,
        authId,
        sortBy,
        status,
        nameLike,
      }) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(feedId && { feedId }),
          ...(tagId && { tagId }),
          ...(topicId && { topicId }),
          ...(userId && { userId }),
          ...(authId && { authId }),
          ...(status && { status }),
          ...(nameLike && { nameLike }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
        });
        return {
          url: `${feed}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Feed"],
    }),

    getPostById: builder.query({
      query: ({ id }) => {
        return {
          url: `${feed}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Feed"],
    }),

    getCommentsById: builder.query({
      query: ({ feedId, limit, offset, status }: GetCommentsDataProps) => {
        const params = new URLSearchParams({
          ...(feedId && { feedId }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(status && { status }),
        });
        return {
          url: `${feed}/comments?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Feed"],
    }),

    getComments: builder.query({
      query: ({
        feedId,
        limit,
        offset,
        status,
        sortOrder,
      }: GetCommentsDataProps) => {
        const params = new URLSearchParams({
          ...(feedId && { feedId }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(status && { status }),
          ...(sortOrder && { sortOrder }),
        });
        return {
          url: `${feed}/comments${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Feed"],
    }),

    getFeedTags: builder.query({
      query: ({ sortBy, nameLike, offset, limit }) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(nameLike && { nameLike }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
        });
        return {
          url: `${feed}/tag?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Feed"],
    }),

    getFeedTopics: builder.query({
      query: ({ sortBy, nameLike, offset, limit }) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(nameLike && { nameLike }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
        });
        return {
          url: `${feed}/topic?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Feed"],
    }),

    postFeedTags: builder.mutation({
      query: ({ data }: PostFeedTagsProps) => {
        return {
          url: `${feed}/tag`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),
    postFeedTopics: builder.mutation({
      query: ({ data }: PostFeedTopicsProps) => {
        return {
          url: `${feed}/topic`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),
    postPosts: builder.mutation({
      query: ({ data }) => {
        return {
          url: `${feed}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),

    postComments: builder.mutation({
      query: ({ data }) => {
        return {
          url: `${feed}/comments`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),

    postPhoto: builder.mutation({
      query: ({ data }) => {
        return {
          url: `${feed}/photo`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),

    postLike: builder.mutation({
      query: ({ id }) => {
        return {
          url: `${feed}/${id}/like`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Feed"],
    }),

    postUnLike: builder.mutation({
      query: ({ id }) => {
        return {
          url: `${feed}/${id}/unLike`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Feed"],
    }),

    postFeedURL: builder.mutation({
      query: ({ data }: PostFeedDataProps) => {
        return {
          url: `${feed}/open-graph`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),

    patchPost: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `${feed}/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),

    patchComment: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `${feed}/comments/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),

    postPostsCommentAbuse: builder.mutation({
      query: ({ data }: PostPostsAbusePayload) => {
        return {
          url: `${feed}/comment-abuse`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["PatchFeedAbuse"],
    }),

    patchPostsCommentAbuse: builder.mutation({
      query: ({ id, data }: PatchPostsAbusePayload) => {
        return {
          url: `${feed}/comment-abuse/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["PatchFeedAbuse"],
    }),

    postPostsAbuse: builder.mutation({
      query: ({ data }: PostPostsAbusePayload) => {
        return {
          url: `${feed}/abuse`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["PatchFeedAbuse"],
    }),

    patchPostsAbuse: builder.mutation({
      query: ({ id, data }: PatchPostsAbusePayload) => {
        return {
          url: `${feed}/abuse/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["PatchFeedAbuse"],
    }),
    patchFeedTag: builder.mutation({
      query: ({ id, data }: PatchFiltersPayload) => {
        return {
          url: `${feed}/tag/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),
    patchFeedTopic: builder.mutation({
      query: ({ id, data }: PatchFiltersPayload) => {
        return {
          url: `${feed}/topic/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Feed"],
    }),
    deleteFeedTag: builder.mutation({
      query: ({ id }: { id: string }) => {
        return {
          url: `${feed}/tag/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Feed"],
    }),
    deleteFeedTopic: builder.mutation({
      query: ({ id }: { id: string }) => {
        return {
          url: `${feed}/topic/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Feed"],
    }),

    deleteComment: builder.mutation({
      query: ({ id }: { id: string }) => {
        return {
          url: `${feed}/comments/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Feed"],
    }),
  }),
});

export const {
  useGetPostsQuery,
  useGetFeedTagsQuery,
  useGetFeedTopicsQuery,
  usePostPostsMutation,
  usePostCommentsMutation,
  usePostLikeMutation,
  usePostUnLikeMutation,
  useGetPostByIdQuery,
  usePatchPostMutation,
  useGetCommentsByIdQuery,
  useLazyGetCommentsByIdQuery,
  usePostPhotoMutation,
  usePatchCommentMutation,
  useLazyGetPostsQuery,
  usePostPostsAbuseMutation,
  usePatchPostsAbuseMutation,
  usePostPostsCommentAbuseMutation,
  usePatchPostsCommentAbuseMutation,
  usePostFeedURLMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  usePostFeedTagsMutation,
  usePostFeedTopicsMutation,
  useDeleteFeedTagMutation,
  useDeleteFeedTopicMutation,
  usePatchFeedTagMutation,
  usePatchFeedTopicMutation,
} = FeedApi;
