import { Button, Form, FormGroup, FormLabel, Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useGetMeQuery } from "redux/services/meApi";
import { useGetCancerQuery } from "redux/services/cancerApi";

interface ChatSupportFormModalProps {
  show: boolean;
  onHide: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

// const SUPPORT_REASONS = [
//   {
//     value: "",
//     label: "--Brak--",
//   },
//   {
//     value: "Czerwona skrzynka",
//     label: "Czerwona skrzynka",
//   },
//   {
//     value: "Skarbonka",
//     label: "Skarbonka",
//   },
//   {
//     value: "HUB",
//     label: "HUB",
//   },
// ];

const ChatSupportFormModal = ({
  show,
  setShow,
  onHide,
}: ChatSupportFormModalProps) => {
  const { data: meData, isLoading: isLoadingMe } = useGetMeQuery({});
  const { data: cancerData, isLoading: isLoadingCancer } = useGetCancerQuery({
    id: meData?.data?.settings?.ID_nowotworu_HUB__c,
  });

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      name: meData?.data?.name,
      email: meData?.data?.email,
      subject: `${meData?.data?.email}${
        cancerData?.data ? ` - ${cancerData?.data?.name}` : ""
      } - Moja Alivia - nowe zapytanie`,
      description: "",
      reason: "HUB",
      orgid: "00D1t000000pZ8y",
      retURL: "http://",
    },
  });

  useEffect(() => {
    if (meData?.data) {
      setValue("name", meData?.data?.name);
      setValue("email", meData?.data?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMe, isLoadingCancer]);

  const onSubmit = async (data: FieldValues) => {
    // data.reason = data.reason.value;
    try {
      const formData = new URLSearchParams();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      await fetch(
        "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D1t000000pZ8y",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "no-cors",
          body: formData,
        },
      );
    } catch (error) {
      console.error("Error:", error);
    }

    reset();
    setShow(false);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" style={{ zIndex: 10000 }}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D1t000000pZ8y"
          method="POST"
          onSubmit={handleSubmit(async (data) => await onSubmit(data))}
        >
          <input type="hidden" name="orgid" value="00D1t000000pZ8y"></input>
          <input type="hidden" name="retURL" value="http://"></input>

          {/*<FormGroup className={"my-2"}>*/}
          {/*  <FormLabel htmlFor={"name"}>Nazwa kontaktu</FormLabel>*/}
          {/*  <Form.Control*/}
          {/*    id="name"*/}
          {/*    {...register("name", {*/}
          {/*      maxLength: 80,*/}
          {/*    })}*/}
          {/*    type="text"*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*</FormGroup>*/}

          <FormGroup className={"my-2"}>
            <FormLabel htmlFor={"email"}>Email</FormLabel>
            <Form.Control
              id="email"
              {...register("email", {
                maxLength: 80,
              })}
              type="text"
              disabled
            />
          </FormGroup>

          {/* <FormGroup className={"my-2"}>
            <FormLabel htmlFor={"phone"}>Telefon</FormLabel>
            <Form.Control
              id="phone"
              maxLength={40}
              {...register("phone", {
                maxLength: 40,
              })}
              type="text"
            />
          </FormGroup> */}

          {/*<FormGroup className={"my-2"}>*/}
          {/*  <FormLabel htmlFor={"subject"}>Temat</FormLabel>*/}
          {/*  <Form.Control*/}
          {/*    id="subject"*/}
          {/*    {...register("subject", {*/}
          {/*      maxLength: 255,*/}
          {/*    })}*/}
          {/*    type="text"*/}
          {/*  />*/}
          {/*</FormGroup>*/}

          <FormGroup className={"my-2"}>
            <FormLabel htmlFor={"description"}>Treść wiadomości</FormLabel>
            <Form.Control
              as={"textarea"}
              {...register("description")}
              type="textarea"
            />
          </FormGroup>
          {/* <FormGroup>
            <FormLabel htmlFor={"reason"}>Przyczyna sprawy</FormLabel>
            <Select
              options={SUPPORT_REASONS}
              {...register("reason")}
              onChange={(value) => setValue("reason", value)}
            />
          </FormGroup> */}

          <Button type={"submit"} className={"my-2 col-12"}>
            Prześlij
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChatSupportFormModal;
