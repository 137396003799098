import {
  Container,
  Row,
  Spinner,
  Carousel,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import styles from "./ArticleWidget.module.scss";
import { useGetArticlesQuery } from "redux/services/articlesApi";
import { useState, useEffect } from "react";
import { getWindowWidth } from "Helpers/getWindowWidth";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { stripHtml } from "Helpers/stripHtml";
import { Link } from "react-router-dom";
import { ArticleCard } from "components/molecules/ArticleCard/ArticleCard";
import { useGetMeQuery, usePatchMeMutation } from "redux/services/meApi";
import { useSelector } from "react-redux";
import type { RootState } from "redux/store/index";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";
import { useGetSFContactQuery } from "redux/services/salesforceApi";
import { fetchArticlesData } from "Helpers/fetchArticlesData";

interface Props {
  breakpointForSingleCard?: number;
  maxCard?: number;
}

interface ArticleData {
  id: string;
  articleSrc?: string;
  categories?: string;
  description: string;
  imgSrc?: string;
  link: string;
  title: string;
}

const ArticleWidget = ({
  breakpointForSingleCard = 991,
  maxCard = 3,
}: Props) => {
  const { data: articleRssData, isLoading } = useGetArticlesQuery({
    limit: "0",
  });
  const { data: sfData } = useGetSFContactQuery({});
  const { data: meData, isLoading: isLoadingGetMe } = useGetMeQuery({});
  const [patchMe, { isLoading: isLoadingPatchMe }] = usePatchMeMutation();

  const { authUser } = useSelector((state: RootState) => state.user);

  const profiling = sfData?.data?.Profilowanie__c as string;

  const [index, setIndex] = useState(0);
  const [maxCardForLatest, setMaxCardForLatest] = useState(
    getWindowWidth() <= breakpointForSingleCard ? 1 : maxCard
  );
  const [articleData, setArticleData] = useState<ArticleData[] | null>(null);
  const [savedArticlesId, setSavedArticlesId] = useState<string[] | null>(null);
  const [show, setShow] = useState(false);
  const [articleWpJSON, setArticleWpJSON] = useState<ArticleData[] | null>(
    null
  );
  const [allArticlesData, setAllArticlesData] = useState<ArticleData[] | null>(
    null
  );

  const { t } = useTranslation();

  useEffect(() => {
    function handleResize() {
      if (getWindowWidth() <= 991) setMaxCardForLatest(1);
      else setMaxCardForLatest(maxCard);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getArticles() {
      try {
        const articleWpJSON = await articleRssData?.articles?.find(
          (article: ArticleRss) => article?.type === "wp-json"
        );

        if (!articleWpJSON) {
          return;
        }

        const responseAllArticles = await fetchArticlesData(articleWpJSON, {
          perPage: "30",
        });
        setAllArticlesData(responseAllArticles);
        setArticleWpJSON(responseAllArticles);
        const responseProfilingArticles = await axios.get(
          `${articleWpJSON?.url}?_embed&per_page=12${
            !profiling || profiling === '""'
              ? ""
              : `&categories_slugs=${profiling}`
          }`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );

        const profilingArticleData = responseProfilingArticles?.data?.map(
          (article: any) => ({
            id: article?.id?.toString() ?? uuidv4(),
            articleSrc: articleWpJSON?.name,
            description: stripHtml(article?.content?.rendered) ?? "",
            imgSrc: article?.yoast_head_json?.og_image[0]?.url ?? "",
            link: article?.link ?? "",
            title: article?.title?.rendered ?? "",
          })
        ) as ArticleData[];
        setArticleData(profilingArticleData.length > 0 ? profilingArticleData : responseAllArticles);
        setArticleWpJSON(profilingArticleData.length > 0 ? profilingArticleData : responseAllArticles);
      } catch (error) {
        console.error("An error occurred while fetching articles:", error);
      }
    }

    getArticles();
    //eslint-disable-next-line
  }, [articleRssData]);

  useEffect(() => {
    if (meData?.data && Array.isArray(meData?.data?.localSettings?.saved_articlesId))
      setSavedArticlesId(meData?.data?.localSettings?.saved_articlesId);

    //eslint-disable-next-line
  }, [meData?.data?.localSettings, isLoadingGetMe, isLoadingPatchMe]);

  useEffect(() => {
    if (show) {
      // Filtruj i wyświetl tylko zapisane artykuły
      const savedArticlesData =
        allArticlesData?.filter((article) =>
          savedArticlesId?.includes(article?.id)
        ) ?? [];
      setArticleData(savedArticlesData);
    } else {
      // Wyświetl wszystkie artykuły
      setArticleData(articleWpJSON);
    }
    //eslint-disable-next-line
  }, [show, savedArticlesId, allArticlesData]);

  const updateSavedArticles = async (articleId: string) => {
    let savedArticlesData: string[] = savedArticlesId || [];

    if (isArticleSaved(articleId)) {
      savedArticlesData = savedArticlesData.filter((id) => id !== articleId);
    } else {
      savedArticlesData = [...savedArticlesData, articleId];
    }

    setSavedArticlesId(savedArticlesData);

    if (Array.isArray(savedArticlesData)) {
      try {
        await patchMe({
          data: {
            ...authUser,
            localSettings: {
              ...authUser.localSettings,
              saved_articlesId: savedArticlesData,
            },
          },
        });
      } catch (error) {
        console.error("Błąd podczas aktualizacji danych użytkownika:", error);
      }
    }
    return;
  };

  const onPrevClick = () => {
    if (articleData?.length) {
      const length = articleData?.length;
      const newIndexBasedOnLength = length / maxCardForLatest;

      if (index < 1) {
        setIndex(
          Number(newIndexBasedOnLength) === newIndexBasedOnLength &&
            newIndexBasedOnLength % 1 === 0
            ? Math.floor(newIndexBasedOnLength) - 1
            : Math.floor(newIndexBasedOnLength)
        );
      } else {
        setIndex(index - 1);
      }
    } else {
      return;
    }
  };

  const onNextClick = () => {
    if (articleData?.length) {
      const length = articleData?.length;
      const newIndexBasedOnLength = length / maxCardForLatest;

      if (index !== 0 && index >= newIndexBasedOnLength - 1) setIndex(0);
      else setIndex(index + 1);
    } else {
      return;
    }
  };

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const isArticleSaved = (articleId: string) =>
    savedArticlesId?.includes(articleId);

  const togglShowSaveArticles = () => {
    setIndex(0);
    setShow((prevState) => !prevState);
  };

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container fluid={true} className="p-4 containerWithShadow">
      <Row>
        <p
          className={`fs-22 fw-600 mb-4 text-dark-blue mx-0 col-9 d-md-flex align-items-center ${styles.title}`}
        >
          <span>{t("widget_article_title")}</span>
        </p>
        {articleData && articleData?.length > maxCardForLatest && (
          <div className="d-flex justify-content-end col-3">
            <div className={styles.buttonCarousel} onClick={onPrevClick}>
              <Arrow
                style={{ transform: "rotate(180deg)" }}
                className="text-dark-blue"
              />
            </div>
            <div className={styles.buttonCarousel} onClick={onNextClick}>
              <Arrow className="text-dark-blue" />
            </div>
          </div>
        )}
      </Row>
      <div className="d-flex flex-row align-items-center my-4">
        <div className="form-check form-switch me-2">
          <input
            checked={show}
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={togglShowSaveArticles}
          />
          <p className="fs-16 fw-600 m-0 text-dark-blue">
            {t("widget_article_p")}
          </p>
        </div>
      </div>
      <Carousel
        indicators={false}
        controls={false}
        pause={"hover"}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={false}
        prevIcon={false}
      >
        {articleData?.map(
          // eslint-disable-next-line
          (_, i: number) => {
            if (i % maxCardForLatest === 0) {
              return (
                <Carousel.Item className="border-danger" key={i}>
                  <Row xs={1} md={maxCardForLatest} className="g-4 mb-4">
                    {Array.from(Array(maxCardForLatest), (_, j) => {
                      const article = articleData?.[i + j];
                      return (
                        <Col className="d-flex align-items-stretch" key={j}>
                          {i + j < articleData?.length ? (
                            <ArticleCard
                              description={article?.description}
                              link={article?.link}
                              title={article?.title}
                              articleSrc={article?.articleSrc}
                              imgSrc={article?.imgSrc}
                              isArticleSaved={isArticleSaved(article?.id)}
                              onIconClick={() =>
                                updateSavedArticles(article?.id)
                              }
                              isPending={isLoadingGetMe || isLoadingPatchMe}
                            />
                          ) : (
                            <Card className="border border-0" />
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel.Item>
              );
            }
          }
        )}
      </Carousel>
      <Col className="d-flex justify-content-end">
        <Button className="col-12 col-md-auto">
          <Link
            to={`/${NAV_PATH.ARTICLES}`}
            onClick={() => window.scrollTo(0, 0)}
          >
            <span className="text-white">{t("widget_article_span")}</span>
          </Link>
        </Button>
      </Col>
    </Container>
  );
};

export default ArticleWidget;