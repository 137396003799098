import AttachmentsContent from "../AttachmentsContent/AttachmentsContent";
import styles from "./MessageContent.module.scss";
import "./MessageContent.css";
import Linkify from "react-linkify";
import React from "react";
type MessageContentProps = {
  message: TopicMessage;
  isSentMessage: boolean;
  avatarSrc: string;
  isOnMessenger?: boolean;
};

const MessageContent = ({
  message,
  isSentMessage,
  avatarSrc,
  isOnMessenger,
}: MessageContentProps) => {
  const messageClass = isSentMessage
    ? `${styles.sentMessage}`
    : `${styles.recievedMessage}`;

  const handleAvatarGeneration = () => {
    if (!isSentMessage) {
      return (
        <img
          src={avatarSrc}
          alt="avatar"
          className={`${styles.avatarImg} me-2`}
        />
      );
    } else if (isSentMessage && isOnMessenger) {
      return (
        <img
          src={avatarSrc}
          alt="avatar"
          className={`${styles.avatarImg} ms-2`}
        />
      );
    } else {
      return null;
    }
  };
  const Avatar = handleAvatarGeneration();

  return (
    <div className={`d-flex mb-4 ${isSentMessage}`}>
      {!isSentMessage && Avatar}

      <div className={messageClass}>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              href={decoratedHref}
              key={key}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {decoratedText}
            </a>
          )}
        >
          <p className="lh-150 m-0">{message?.message}</p>
        </Linkify>
        <AttachmentsContent attachments={message?.attachment} />
      </div>

      {isOnMessenger && isSentMessage && Avatar}
    </div>
  );
};

export default MessageContent;
