import { topic } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Topic", "TopicMessage", "TopicCase"],
});

export const topicApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTopics: builder.query({
      query: ({ limit, offset, topicUserId, senderId, status, caseId }) => {
        const params = new URLSearchParams({
          ...(offset && { offset: offset.toString() }),
          ...(limit && { limit: limit.toString() }),
          ...(topicUserId && { topicUserId: topicUserId.toString() }),
          ...(senderId && { senderId: senderId.toString() }),
          ...(status && { status: status.toString() }),
          ...(caseId && { caseId: caseId.toString() }),
        });

        return {
          url: `${topic}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Topic"],
    }),

    createTopic: builder.mutation({
      query: (newTopic) => ({
        url: topic,
        method: "POST",
        body: newTopic,
      }),
      invalidatesTags: ["Topic"],
    }),
    updateTopic: builder.mutation({
      query: ({ id, data }: UpdateTopicPayload) => ({
        url: `${topic}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Topic"],
    }),
    getTopic: builder.query({
      query: (id) => `${topic}/${id}`,
      providesTags: ["Topic"],
    }),

    markTopicAsRead: builder.mutation({
      query: ({ id, data }: MarkTopicAsReadPayload) => ({
        url: `${topic}/${id}/read-topic`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Topic"],
    }),

    getTopicMessages: builder.query({
      query: ({ limit, offset }) => {
        const params = new URLSearchParams({
          ...(offset && { offset: offset.toString() }),
          ...(limit && { limit: limit.toString() }),
        });

        return {
          url: `${topic}-message?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["TopicMessage"],
    }),

    createTopicMessage: builder.mutation({
      query: (newMessage) => ({
        url: `${topic}-message`,
        method: "POST",
        body: newMessage,
      }),
      invalidatesTags: ["TopicMessage"],
    }),
    updateTopicMessage: builder.mutation({
      query: ({ id, updatedMessage }) => ({
        url: `${topic}-message/${id}`,
        method: "PATCH",
        body: updatedMessage,
      }),
      invalidatesTags: ["TopicMessage"],
    }),
    getTopicMessage: builder.query({
      query: (id) => `${topic}-message/${id}`,
      providesTags: ["TopicMessage"],
    }),
    getTopicCases: builder.query({
      query: ({ limit, offset }) => {
        const params = new URLSearchParams({
          ...(offset && { offset: offset.toString() }),
          ...(limit && { limit: limit.toString() }),
        });

        return {
          url: `${topic}-case?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["TopicCase"],
    }),

    createTopicCase: builder.mutation({
      query: (newCase) => ({
        url: `${topic}-case`,
        method: "POST",
        body: newCase,
      }),
      invalidatesTags: ["TopicCase"],
    }),
    updateTopicCase: builder.mutation({
      query: ({ id, updatedCase }) => ({
        url: `${topic}-case/${id}`,
        method: "PATCH",
        body: updatedCase,
      }),
      invalidatesTags: ["TopicCase"],
    }),
    getTopicCase: builder.query({
      query: (id) => `${topic}-case/${id}`,
      providesTags: ["TopicCase"],
    }),
  }),
});

export const {
  useGetTopicsQuery,
  useCreateTopicMutation,
  useUpdateTopicMutation,
  useGetTopicQuery,
  useGetTopicMessagesQuery,
  useCreateTopicMessageMutation,
  useUpdateTopicMessageMutation,
  useGetTopicMessageQuery,
  useGetTopicCasesQuery,
  useCreateTopicCaseMutation,
  useUpdateTopicCaseMutation,
  useGetTopicCaseQuery,
  useMarkTopicAsReadMutation,
} = topicApi;
