import { ReactComponent as Download } from "../../../../assets/Icons/download.svg";
import { ReactComponent as Attachment } from "../../../../assets/Icons/Attachment.svg";
import styles from "./AttachmentsContent.module.scss";

type AttachmentsContentProps = {
  attachments: Attachment[];
};

const AttachmentsContent = ({ attachments }: AttachmentsContentProps) => {
  const downloadFile = async (att: Attachment) => {
    const response = await fetch(att?.accessUrl);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = att?.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const truncateFilename = (filename: string, maxLength: number) => {
    if (filename.length > maxLength) {
      return `${filename.substring(0, maxLength)}...`;
    }
    return filename;
  };

  const isImage = (filename: string) => {
    if (!filename) {
      return false;
    }
    const ext = filename.split(".").pop()?.toLowerCase();
    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext || "");
  };

  return (
    <div className="d-flex flex-column">
      {attachments?.map((att, attIndex: number) => (
        <div key={attIndex} className="mb-1">
          {isImage(att?.filename || "") && (
            <img
              src={att?.accessUrl}
              alt={att?.filename}
              className={`me-2 mb-2 ${styles.attachmentThumbnail}`}
            />
          )}
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Attachment className="me-2" />
              <p className="m-0">{truncateFilename(att?.filename || "", 20)}</p>
            </div>
            <a
              href={att?.accessUrl}
              target="_blank"
              rel="noreferrer"
              download
              onClick={() => downloadFile(att)}
              className={`${styles.download}`}
            >
              <Download />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AttachmentsContent;
