import styles from "./Messages.module.scss";
import { Container, Offcanvas } from "react-bootstrap";
import { useEffect } from "react";
import { MessagesChatView } from "components/molecules/MessagesChatView/MessagesChatView";
import { MessagesMainView } from "components/molecules/MessagesMainView/MessagesMainView";
import { MessagesTopicView } from "components/molecules/MessagesTopicView/MessagesTopicView";
import { useGetTopicsQuery } from "redux/services/topicApi";
import { useGetMeQuery } from "redux/services/meApi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store/index";
import { View, setShowMessages } from "redux/Slice/messages-slice";
import useScreenSize from "Helpers/useScreenSize";
import { ReactComponent as Close } from "../../../assets/Icons/DeleteIcon.svg";

export const Messages = () => {
  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  const view = useSelector((state: RootState) => state.messages.view);
  const showMessages = useSelector(
    (state: RootState) => state.messages.showMessages
  );
  const onMessage = useSelector(
    (state: RootState) => state.webSocket.onMessage
  );
  const CLOSED_CONVERSATION_STATUS = 4;
  const { data: userData } = useGetMeQuery({});
  const userId = Number(userData?.data.id);

  const {
    data: messages,
    isLoading,
    isError,
    refetch,
  } = useGetTopicsQuery({ topicUserId: userId, limit: "0" });

  const { data: archiveMessages } = useGetTopicsQuery({
    topicUserId: userId,
    limit: "0",
    status: CLOSED_CONVERSATION_STATUS,
  });

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }

    if (onMessage) {
      refetch();
    }
  }, [isLoading, isError, refetch, onMessage]);

  const messagesData = messages?.data || [];
  const archivedChats = archiveMessages?.data || [];

  const combinedChats = [...messagesData, ...archivedChats];

  const viewComponents = {
    [View.CHAT]: <MessagesChatView className="p-3 p-md-4" />,
    [View.MAIN]: <MessagesMainView chats={combinedChats} />,
    [View.TOPIC]: <MessagesTopicView chats={messagesData} />,
  };

  return (
    <Offcanvas
      show={showMessages}
      onHide={() => dispatch(setShowMessages(false))}
      backdropClassName="bg-white p-0"
      placement="end"
      className={`${styles.offcanvas}`}
    >
      {screenSize === "small" && (
        <div
          className={`d-flex justify-content-between p-3 ${styles.mobileHeader}`}
        >
          <p className="fs-22 fw-600 m-0">Wiadomości</p>
          <Close
            className={`${styles.close}`}
            onClick={() => dispatch(setShowMessages(!showMessages))}
          />
        </div>
      )}
      <Container className={`col-12 p-0 ${styles.container}`}>
        {viewComponents[view] || <MessagesMainView chats={combinedChats} />}
      </Container>
    </Offcanvas>
  );
};
