import styles from "./EventCard.module.scss";
import PlaceholderImg from "../../../assets/images/Alivia_EventCoverPlaceholder.svg";
import { Button, Card, Row } from "react-bootstrap";
import { capitalizeString } from "Helpers/capitalizeString";
import { useNavigate } from "react-router";
import { EventCardProps } from "./types";
import { EventAdress } from "components/organisms/AddEventModal/types";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { Link } from "react-router-dom";
import { convertHtmlToText } from "Helpers/convertHtmlToText";
import formatEventDateForRange from "../../../Helpers/formatEventDateForRange";

export const EventCard = ({ data, noShadow }: EventCardProps) => {
  const navigate = useNavigate();
  
  const categoriesNames = data?.categories?.map((category: EventCategory) =>
    capitalizeString(category?.title)
  );

  const addressObject: EventAdress = 
    data?.address?.startsWith("{") ? JSON.parse(data?.address) : undefined;

  const formattedAddress = addressObject
    ? `${addressObject?.street ? `ul. ${addressObject.street} ` : ""}${addressObject?.houseNumber || ""}${
        addressObject?.apartmentNumber
          ? " / " + addressObject?.apartmentNumber
          : ""
      }`
    : addressObject ? `ul. ${data.address}` : "";

  const eventInfo = {
    date: formatEventDateForRange(data, true),
    categories: categoriesNames,
    place: [formattedAddress, data?.city?.name, data?.province?.name].filter(Boolean),
  };

  const openEventPage = () => {
    window.scrollTo(0, 0);
    navigate(`/${NAV_PATH.EVENTS}/${data?.id}`);
  };

  const renderEventInfo = (info: string | string[] | undefined, key: string) => {
    if (!info) return null;
    return (
      <span
        key={key}
        className={`${styles.EventInfo} ${key === "date" ? styles.first : ""} ${
          key === "place" ? styles.last : ""
        }`}
      >
        {Array.isArray(info) ? info.join(", ") : info}
      </span>
    );
  };

  const renderEventInfoForMobile = (
    info: string | string[] | undefined,
    key: string
  ) => {
    if (!info) return null;
    let label = "";
    switch (key) {
      case "date":
        label = "Data";
        break;
      case "categories":
        label = "Kategoria";
        break;
      case "place":
        label = "Miejsce";
        break;
      default:
        label = "";
        break;
    }

    return (
      <p
        key={key}
        className={`py-2 d-flex justify-content-between align-items-center border-bottom m-0`}
      >
        <span className="fs-14 fw-300 text-grey lh-130">{label}</span>
        <span className="fs-14 fw-400 lh-130 text-dark-blue ms-s w-75 text-end">
          {Array.isArray(info) ? info.join(", ") : info}
        </span>
      </p>
    );
  };

  const eventDescription = convertHtmlToText(data?.description);
  const truncatedDescription =
    eventDescription.length > 208
      ? `${eventDescription.substring(0, 205)}...`
      : eventDescription;

  return (
    <Card className={!noShadow ? `border-2 border ${styles.card}` : `border-1 border`}>
      <Row>
        <div className="col-md-3">
          <Card.Img
            className={styles.img}
            variant="top"
            src={data?.cover?.accessUrl || PlaceholderImg}
          />
        </div>
        <div className="col-md-9 position-relative">
          <Card.Body>
            <Card.Title className={`mb-4 mt-1 fs-19 fw-semibold lh-130 ls-3 text-dark-blue ${styles.titleAfter}`}>
              <Link to={`/${NAV_PATH.EVENTS}/${data?.id}`}>{data?.name}</Link>
            </Card.Title>
            <Card.Subtitle className="mt-1 mb-4 d-none d-md-inline">
              {Object.entries(eventInfo).map(([key, value]) => renderEventInfo(value as string, key))}
            </Card.Subtitle>
            <Card.Subtitle className="mt-1 mb-4 d-inline d-md-none">
              {Object.entries(eventInfo).map(([key, value]) => renderEventInfoForMobile(value as string, key))}
            </Card.Subtitle>
            <Card.Text as="p" className="d-none d-md-block mt-4 mb-3 text-dark-blue">
              {truncatedDescription}
            </Card.Text>
            <Button
              variant="outline-primary"
              onClick={openEventPage}
              className={`mt-3 ${styles.ReadMoreButton}`}
            >
              CZYTAJ WIĘCEJ
            </Button>
          </Card.Body>
        </div>
      </Row>
    </Card>
  );
};