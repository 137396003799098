import { PatchMeUserPayload } from "redux/Slice/user-slice";
import { me } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";
import Cookies from "universal-cookie";

interface SendUserData {
  data: PatchMeUserPayload;
}

interface SendChangePassword {
  data: {
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat?: string;
  };
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Me"],
});

export const meApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => {
        return {
          url: `${me}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${new Cookies().get("MOJA_TKN")}`,
          },
        };
      },
      providesTags: ["Me"],
    }),
    patchMe: builder.mutation({
      query: ({ data }: SendUserData) => ({
        url: `${me}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Me"],
    }),
    changePassword: builder.mutation({
      query: ({ data }: SendChangePassword) => ({
        url: `${me}/password`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Me"],
    }),
  }),
});

export const { useGetMeQuery, useLazyGetMeQuery, usePatchMeMutation, useChangePasswordMutation } =
  meApi;
