import styles from "./LoyaltyProgramServiceCard.module.scss";
import { Badge, Card } from "react-bootstrap";
import PlaceholderImgSmall from "../../../assets/images/benefitSmall.png";
import { LoyaltyProgramServiceDataInterface } from "../../templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate";
import { polishPlural } from "../../../Helpers/polishPlural";
import { Link } from "react-router-dom";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface Props {
  data: LoyaltyProgramServiceDataInterface;
  isLatest?: boolean;
  isTiles?: boolean;
  isNew?: boolean;
}

export const LoyaltyProgramServiceCard = ({
  data,
  isLatest = false,
  isTiles = true,
  isNew = false, 
}: Props) => {
  return (
    <Link
      className={"w-100"}
      to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${data.id}`}
    >
      <Card
        as="article"
        className={`h-100 ${isTiles ? styles.card : styles.cardList} ${
          "border-2 border"
        }`}
      >
        <div>
          <Card.Img
            className={`rounded-0 ${
              isTiles ? "border-2 border-bottom rounded-top" : "border-2 rounded-top"
            } ${styles.img}`}
            src={
              convertImageIdToUrl(data.imageId, false, "small.jpeg") ||
              convertImageIdToUrl(data.imageId, true, "small.jpeg") ||
              PlaceholderImgSmall
            }
          />
          <div className={"position-absolute top-0 start-0 d-flex flex-wrap"}>
            {(isLatest || isNew) && !data.priorityCategory.some(category => category.name === "Alivia Poleca") && (
              <Badge className="mt-12 ms-3 fs-10 fw-semibold lh-130 ls-6 text-white bg-primary">
                Nowość
              </Badge>
            )}
            {data.daysToEnd && (
              <Badge className="mt-12 ms-3 fs-10 fw-semibold lh-130 ls-6 text-white bg-dark-blue">
                {data.daysToEnd} {data.daysToEnd === 1 ? "dzień" : "dni"}{" "}
                do końca
              </Badge>
            )}
            {data.priorityCategory.map((category, index) => (
              <Badge
                key={index}
                className="mt-12 ms-3 fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary"
              >
                {category.name}
              </Badge>
            ))}
          </div>
        </div>
        <Card.Body
          className={`d-flex flex-column justify-content-between`}
        >
          <Card.Title
            as="h3"
            className={`fs-19 fw-semibold lh-130 ls-3 text-dark-blue pb-2`}
          >
            {data.name}
          </Card.Title>
          <div className={""}>
            <Card.Text className={"text-dark-blue fs-14 fw-bolder mb-1"} as="p">
              {data.company.name}
            </Card.Text>
            <Card.Text className={"text-dark-blue fs-14"} as="p">
              {data.departmentCount}{" "}
              {polishPlural(
                "lokalizacja",
                "lokalizacje",
                "lokalizacji",
                data.departmentCount,
              )}
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
};