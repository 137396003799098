import React from 'react';
import Calendar from 'react-calendar';
import './CalendarOverrides.css';

interface CustomCalendarProps {
  onChange: (date: Date) => void;
  value?: Date;
  maxDate?: Date;
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({ onChange, value, maxDate }) => {
  return (
    <div className="custom-calendar">
      <Calendar
        onChange={(value, event) => onChange(value as Date)}
        value={value}
        maxDate={maxDate}
      />
    </div>
  );
};

export default CustomCalendar;