import { Row } from "react-bootstrap";
import { setView, View } from "redux/Slice/messages-slice";
import { ReactComponent as Pen } from "../../../../assets/Icons/pen.svg";
import styles from "./TopicInfo.module.scss";
import { useDispatch } from "react-redux";

type TopicInfoProps = {
  selectedTopic: ChatCase;
};

const TopicInfo = ({ selectedTopic }: TopicInfoProps) => {
  const dispatch = useDispatch();

  return (
    <Row className="m-0 col-12 flex-grow-1 text-dark">
      <div className="d-flex p-4 flex-column justify-content-end align-items-center">
        <p className={`text-grey-2 fs-12 w-100`}>Powód kontaktu</p>
        <div className="d-flex w-100 align-items-center m-0 p-0">
          <p className={`w-100 m-0 ${styles.topic}`}>{selectedTopic.case}</p>
          <button
            className={`ms-12 p-0 ${styles.editTopicBtn}`}
            onClick={() => dispatch(setView(View.TOPIC))}
          >
            <Pen />
          </button>
        </div>
      </div>
    </Row>
  );
};

export default TopicInfo;
